<template>  
    <v-layout justify-center class="pt-9" fill-height> 
        <mbs-page-spinner v-if="!DayShift" />  
        <mbs-epson-display-temp1 v-else
            :show="true"
            :loading="inputLoading"
            :print_request="CASHUP_PRINT_REQUEST"/> 
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState } from "vuex"
    let PAGE_NAME  = 'DAY-SHIFT-TEMPLATE-1'
    export default {
        props:['data','cashup','day_shift','sales_type','closing_cash'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                CASHUP_PRINT_REQUEST:null,
                loadingText:false,
                inputLoading:true,
                currency_list:[
                    {value:1,name:"1.00",icon:"mdi-cash"},
                    {value:5,name:"5.00",icon:"mdi-cash"},
                    {value:10,name:"10.00",icon:"mdi-cash"},
                    {value:20,name:"20.00",icon:"mdi-cash"},
                    {value:50,name:"50.00",icon:"mdi-cash"},
                    {value:100,name:"100.00",icon:"mdi-cash"},
                    {value:200,name:"200.00",icon:"mdi-cash"},
                    {value:500,name:"500.00",icon:"mdi-cash"},
                    {value:1000,name:"1,000.00",icon:"mdi-cash"},
                    {value:2000,name:"2,000.00",icon:"mdi-cash"},
                    {value:5000,name:"5,000.00",icon:"mdi-cash"},
                ], 
            } 
        }, 
        mounted(){ 
           this.EPSON_CASHUP_REQUEST()
        },
        computed:{
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
            Cashup(){
                let join = this.MBS.actions.JOIN_CASHUP(this.data) 
               return join
            },
            DayShift(){
                let cashup = this.Cashup
                let day_shift = cashup?cashup.day_shift:null
                let join = this.MBS.actions.JOIN_DAY_SHIFT(day_shift) 
               return join
            },    
        },
        methods:{
            async EPSON_CASHUP_REQUEST(){ 
                try {  
                    let cashup = this.Cashup  
                    let day_shift = this.DayShift
                    this.inputLoading = true
                    let res = await this.MBS.actions.EPSON_CASHUP_REQUEST({
                        cashup:cashup,
                        day_shift:day_shift,
                        printer:{
                            paper_size:38
                        }
                    }) 
                    this.CASHUP_PRINT_REQUEST = res?res.data:null 
                    this.inputLoading = false
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EPSON_CASHUP_REQUEST',
                        page:PAGE_NAME, 
                    }) 
                    return 
                }   
            },   
        },
        watch:{
            mbs_dialog(value){ 
            },   
            DayShift(value){ 
                this.EPSON_CASHUP_REQUEST()
            },   
            Cashup(value){ 
                this.EPSON_CASHUP_REQUEST()
            },   
        } 
    }
</script>
